import React, { useState, useEffect, useContext, Component } from "react";
import { useNavigate, Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import axios from "axios";
import { useDispatch } from "react-redux";
import { authActions } from "../../redux/store";
import { toast } from "react-hot-toast";
import Header from "../components/Header";
import Footer from "../components/Footer";

const About = () => {
  return (
    <>
      <Header />

      <main className="page">
        {/* Header */}
        {/* Header */}
        {/* Page Title */}
        <div className="py-4 mb-4 mb-lg-10 whitesmoke">
          <div className="container d-lg-flex justify-content-between align-items-center py-2 py-lg-4">
            <div className="pe-lg-4 text-center text-lg-start">
              <h1 className="h3 mb-0">About Us</h1>
            </div>
            <div className="pt-2 pt-lg-0">
              <nav aria-label="breadcrumb">
                <ol className="breadcrumb flex-lg-nowrap justify-content-center justify-content-lg-start">
                  <li className="breadcrumb-item">
                    <Link className="text-nowrap" to="/" previewlistener="true">
                      <i className="ri-store-2-line" /> Home{" "}
                    </Link>
                  </li>

                  <li
                    className="breadcrumb-item text-nowrap active"
                    aria-current="page"
                  >
                    About Us
                  </li>
                </ol>
              </nav>
            </div>
          </div>
        </div>
        {/* Page Title */}
        {/* Content */}
        <div className="container mb-4 mb-lg-10">
          <div className="row g-4 g-lg-10">
            <div className="col-12 col-lg-12">
              <img src="/assets/img/medical-image.webp" class="rounded mb-4" />
              <h2>Who We Are</h2>
              <p>
                Welcome to YNB Healthcare, your trusted partner for all
                healthcare needs in Delhi NCR. At YNB Healthcare, we are
                dedicated to providing high-quality healthcare services and
                medical equipment rentals to ensure that patients receive the
                best possible care in the comfort of their own homes. We
                understand the importance of timely and reliable healthcare,
                which is why we aim to make it accessible, affordable, and
                efficient for families in the region. Our comprehensive services
                cover a wide range of healthcare needs, from ICU-level care at
                home to specialized support for patients recovering from
                surgery. We pride ourselves on our team of experienced and
                compassionate professionals who work tirelessly to deliver
                exceptional care and support to each patient. Here’s an overview
                of the key services we offer:
              </p>
              <hr />
              <h2>Our Services</h2>

              <div className="row">
                <div className="col-md-4">
                  <div className="card bg-light">
                    <div className="card-body">
                      <h5 className="card-title">Home ICU Services</h5>
                      <p className="card-text service-description">
                        We bring ICU-level care to your home with advanced
                        equipment and professional staff, ensuring comfort and
                        safety at all times.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="card bg-light">
                    <div className="card-body">
                      <h5 className="card-title">Home Nursing</h5>
                      <p className="card-text service-description">
                        Our skilled nurses provide round-the-clock care for
                        patients with ongoing medical needs, ensuring their
                        comfort and well-being.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="card bg-light">
                    <div className="card-body">
                      <h5 className="card-title">Patient Care Takers</h5>
                      <p className="card-text service-description">
                        Our compassionate caregivers assist patients with daily
                        living activities, ensuring they feel supported and
                        cared for at home.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              {/* Second Row of Services */}
              <div className="row mt-4">
                <div className="col-md-4">
                  <div className="card bg-light">
                    <div className="card-body">
                      <h5 className="card-title">Mother &amp; Baby Care</h5>
                      <p className="card-text service-description">
                        Expert Japa Maids and baby sitters offer caring support
                        for new mothers and their newborns, ensuring both are
                        well cared for after delivery.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="card bg-light">
                    <div className="card-body">
                      <h5 className="card-title">Medical Equipment on Rent</h5>
                      <p className="card-text service-description">
                        We provide rental options for essential medical
                        equipment, including oxygen concentrators, ventilators,
                        and hospital beds to help with recovery at home.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="card bg-light">
                    <div className="card-body">
                      <h5 className="card-title">Ambulance Services</h5>
                      <p className="card-text service-description">
                        24/7 ambulance services, including ICU and outstation
                        transfers, to ensure fast and safe transportation for
                        patients in need of emergency care.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Cards */}
      </main>

      <Footer />
    </>
  );
};

export default About;
