// axiosInstance.js
import axios from "axios";

const axiosInstance = axios.create({
  baseURL: "https://backend-2o7f.onrender.com/",
});

export default axiosInstance;

export const weburl = "http://localhost:3050/";
export const Homeurl = "https://ynbhealthcare.com";

// export const weburl = 'https://backend-2o7f.onrender.com/'
